/**
 *
 * You can write your JS code here, DO NOT touch the default style file
 * because it will make it harder for you to update.
 *
 */

"use strict";

function show_Loading() {
  jQuery("body").addClass('is_loading')
}

function hide_Loading() {
  jQuery("body").removeClass('is_loading')
}
